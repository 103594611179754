<template>
  <div class="info">
    <van-popup v-model="CommunityShow"  position="bottom" :style="{ height: '40%' }" >
      <van-picker title="请选择" show-toolbar :columns="selectData.CommunityList" value-key="name" @confirm="CommunityConfirm"  @cancel="CommunityShow = false"/>
    </van-popup>
    <van-popup v-model="BuildingShow"   position="bottom"  >
      <van-notice-bar color="#1989fa" background="#ecf9ff" left-icon="info-o" v-if="selectData.BuildingList.length < 1 ">没有获取到楼幢信息,请先选择小区或切换小区</van-notice-bar>
      <van-picker title="请选择" show-toolbar :columns="selectData.BuildingList" value-key="label"  @confirm="BuildingConfirm"  @cancel="BuildingShow = false"/>
    </van-popup>

    <van-popup v-model="unitShow"   position="bottom"  >
      <van-notice-bar color="#1989fa" background="#ecf9ff" left-icon="info-o" v-if="selectData.unitList.length < 1 ">没有获取到单元信息,请先选择楼幢或切换楼幢</van-notice-bar>
      <van-picker title="请选择" show-toolbar :columns="selectData.unitList" value-key="label"  @confirm="unitConfirm"  @cancel="unitShow = false"/>
    </van-popup>

    <van-popup v-model="houseShow"   position="bottom"  >
      <van-notice-bar color="#1989fa" background="#ecf9ff" left-icon="info-o" v-if="selectData.houseList.length < 1 ">没有获取到房屋信息,请先选择单元或切换单元</van-notice-bar>
      <van-picker title="请选择" show-toolbar :columns="selectData.houseList" value-key="label"  @confirm="houseConfirm"  @cancel="houseShow = false"/>
    </van-popup>

    <van-popup v-model="problemShow"   position="bottom"  >
      <van-picker title="请选择" show-toolbar :columns="selectData.problemList" value-key="label"  @confirm="problemConfirm"  @cancel="problemShow = false"/>
    </van-popup>

    <van-cell-group>
      <van-cell   >
        <template #title>
          <div class="blue-box"></div>
          <span class="custom-title">新增握一握</span>
        </template>
      </van-cell>
      <van-cell title="所在小区" is-link :value="selectMsg.Community" @click="CommunityShow = true"/>
      <van-cell title="所在楼幢" is-link :value="selectMsg.Building"  @click="BuildingShow = true"/>
      <van-cell v-if="divideUnit === '1'" title="所在单元" is-link :value="selectMsg.unit" @click="unitShow = true" />
      <van-cell v-if="divideHouse === '1'" title="所在房屋" is-link :value="selectMsg.house" @click="houseShow = true"/>
      <van-cell title="选择问题" is-link :value="selectMsg.problem" @click="problemShow = true"/>
    </van-cell-group>
    <van-row class="btns">
      <van-col :span="24" style="padding-top: 80px">
        <van-button type="info" size="large" round @click="submit">完成</van-button>
      </van-col>
    </van-row>
  </div>
</template>

<script>
import {getDictTree} from '@/utils/common'

export default {
  data () {
    return {
      CommunityShow: false,
      BuildingShow: false,
      unitShow: false,
      houseShow: false,
      problemShow: false,
      divideHouse: '1',
      divideUnit: '1',
      selectMsg: {
        Community: '请选择',
        position: '请选择',
        Building: '请选择',
        house: '请选择',
        unit: '请选择',
        problem: '请选择'
      },
      selectData: {
        problemList: [],
        CommunityList: [],
        BuildingList: [],
        unitList: [],
        houseList: []
      },
      submitData: {
        problem: '',
        building:'',
        content:'',
        fileMessages:[],
        house:'',
        location:'',
        orgId:0,
        subarea:'',
        type:'',
        unit:'',
        userId:''
      },
    }
  },
  methods: {
    submit () {
      if (this.selectMsg.Community === '请选择') {return this.$toast.fail('失败:请先选择小区');}
      if (this.selectMsg.Building === '请选择') {return this.$toast.fail('失败:请先选择楼幢');}
      if ( this.selectMsg.unit === '请选择' && this.divideUnit === '1') {return this.$toast.fail('失败:请先选择单元');}
      if ( this.selectMsg.house === '请选择'  && this.divideHouse === '1') {return this.$toast.fail('失败:请先选择房屋');}
      if ( this.selectMsg.problem === '请选择') {return this.$toast.fail('失败:请先选择问题类型');}
      this.$httpApp({
        url: this.$httpApp.adornUrl('/wxapp/future/communications/save'),
        method: 'post',
        data: this.$httpApp.adornData({
          userId: this.submitData.userId,
          subarea: this.submitData.subarea,            //所在小区id
          type: this.submitData.problem,             //问题类型
          houseId: this.submitData.house,      // 房屋
          building: this.submitData.building,
          unit: this.submitData.unit,
          orgId:this.submitData.orgId,
          changeProblem: '0',
          isFinish: '0'
        },false)
      }).then(({data})=> {
        if (data.code == 0) {
          this.$toast.success('新增成功')
          this.$router.back()
        } else {
          this.$toast.fail(data.msg);
        }
      })
    },
    init () {
      this.submitData.userId = this.$globalData.userInfo.userId
      this.submitData.orgId = this.$orgId
      getDictTree({code: 'futureCommunicationsType', orgId: this.submitData.orgId}, (res)=> {
        this.selectData.problemList = res
      })
      this.$http({
        url: this.$http.adornUrl('/wxapp/sys/org/subAreaList'),
        method: 'post',
        params: this.$http.adornParams({
          communityId: this.$orgId
        },false)
      }).then(({data})=> {
        if (data.code == 0) {
          this.selectData.CommunityList = data.subAreaList
        } else {
          this.$toast.fail(data.msg);
        }
      })
    },
    // 楼栋选择
    BuildingConfirm (value) {
      if (value) {
        this.selectMsg.Building = value.label
        this.submitData.building = value.value
        this.divideHouse = value.divideHouse
        this.divideUnit = value.divideUnit
        this.selectMsg.unit = '请选择'
        this.submitData.unit = ''
        this.selectData.houseList = []
        this.selectMsg.house = '请选择'
        this.submitData.house = ''
      }
      if (this.divideUnit === '0') {
        this.$http({
          url: this.$http.adornUrl('/wxapp/building/house/listByUpId'),
          method: 'post',
          params: this.$http.adornParams({
            upId: value.value,
            bOru: 2
          },false)
        }).then(({data})=> {
          if (data.code == 0) {
            console.log(data)
            if (this.divideHouse === '0') {
              this.submitData.house =data.houseOptions[0].value
            } else {
              this.selectData.houseList = data.houseOptions
            }
          } else {
            this.$toast.fail(data.msg);
          }
        })
      }
      this.$http({
        url: this.$http.adornUrl('/wxapp/building/unit/listByBuildingId'),
        method: 'post',
        params: this.$http.adornParams({
          buildingId: value.value
        },false)
      }).then(({data})=> {
        if (data.code == 0) {
          this.selectData.unitList = data.units
        } else {
          this.$toast.fail(data.msg);
        }
      })
      this.BuildingShow = false
    },
    //小区选择
    CommunityConfirm (value) {
      if (value) {
        this.selectMsg.Community = value.name
        this.submitData.subarea = value.id
        this.selectMsg.Building = '请选择'
        this.selectMsg.unit = '请选择'
        this.submitData.unit = ''
        this.selectData.unitList = []
        this.selectData.houseList = []
        this.selectMsg.house = '请选择'
        this.submitData.house = ''
        this.selectData.BuildingList = []
        this.divideUnit = '1'
        this.divideHouse = '1'
        this.$http({
          url: this.$http.adornUrl('/wxapp/building/info/getBySubArea'),
          method: 'post',
          params: this.$http.adornParams({
            subArea: value.id
          },false)
        }).then(({data})=> {
          if (data.code == 0) {
            this.selectData.BuildingList = data.buildings
          } else {
            this.$toast.fail(data.msg);
          }
        })
      }
      this.CommunityShow = false
    },
    // 单元选择
    unitConfirm (value) {
      if(value) {
        this.selectMsg.unit = value.label
        this.submitData.unit = value.value
        this.selectMsg.house = '请选择'
        this.submitData.house = ''
      }
      this.$http({
        url: this.$http.adornUrl('/wxapp/building/house/listByUpId'),
        method: 'post',
        params: this.$http.adornParams({
          upId: value.value,
          bOru: 2
        },false)
      }).then(({data})=> {
        if (data.code == 0) {
          console.log(data);
          this.selectData.houseList = data.houseOptions
        } else {
          this.$toast.fail(data.msg);
        }
      })
      this.unitShow = false
    },
    // 房屋选择
    houseConfirm (value) {
      if(value) {
        this.selectMsg.house = value.label
        this.submitData.house = value.value
      }
      this.houseShow = false
    },
    problemConfirm (value) {
      if(value) {
        this.selectMsg.problem = value.label
        this.submitData.problem = value.value
      }
      this.problemShow = false
    }
  },
  mounted() {
    console.log(this.$globalData)
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.info {
  min-height: 100vh;
  background-color: #fff !important;
}
</style>
